import React from "react";
import Window from "./Window";
import * as styles from "../styles/MainWindow.module.scss";
// const indent = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;
const indentDouble = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;

// const aspirations = [
//   "Help your business grow",
//   "Make the world better with software",
// ];

const favorites = {
  company: "Tesla",
  role: "Senior Distributed Systems Engineer",
  languages: ["Go", "Ruby"],
  alumnus: "Iowa State University"
};

const MainWindow = () => {
  return (
    <div className={[styles.wrapper].join(" ")}>
      <Window fade={false} title={"about_patrick.json"}>
        <div className={[styles.content, "center-y"].join(" ")}>
          <p>
            <span>{"{"}</span>
          </p>
          {/* <p>
            <span className={[styles.key].join(" ")}>
              {indent}&quot;aspirations&quot;
            </span>
            <span>: &nbsp;[</span>
          </p> */}
          {/* {aspirations.map((aspiration, i) => (
            <p key={i}>
              <span className={[styles.value].join(" ")}>
                {indentDouble}&quot;{aspiration}&quot;
              </span>
              {i != Object.keys(aspirations).length - 1 && <span>,</span>}
            </p>
          ))}
          <p>
            <span>{indent}],</span>
          </p>
          <br /> */}

          {/* <p>
            <span className={[styles.key].join(" ")}>
              {indent}&quot;favorites&quot;
            </span>
            <span>: &nbsp;{"{"}</span>
          </p> */}
          {Object.keys(favorites).map((fav, i) => (
            <p key={fav}>
              <span className={[styles.key].join(" ")}>
                {indentDouble}&quot;{fav}&quot;
              </span>
              <span>:&nbsp;</span>
              {typeof favorites[fav] === "string" ? (
                <span className={styles.value}>
                  &quot;{favorites[fav]}&quot;
                </span>
              ) : (
                <>
                  <span>[</span>
                  {favorites[fav].map((f, i, a) => (
                    <span key={f} className={styles.value}>
                      &quot;{f}&quot;
                      {i !== a.length - 1 && (
                        <span style={{ color: "white" }}>,&nbsp;</span>
                      )}
                    </span>
                  ))}
                  <span>]</span>
                </>
              )}
              {i != Object.keys(favorites).length - 1 && <span>,</span>}
            </p>
          ))}
          {/* <p>
            <span>
              {indent}
              {"}"}
            </span>
          </p> */}
          <p>
            <span>{"}"}</span>
          </p>
        </div>
      </Window>
    </div>
  );
};

export default MainWindow;
